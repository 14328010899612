import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../constant/constant";

const UserTable = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [usersPerPage] = useState(10);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/v1/users?page=${currentPage}&limit=${usersPerPage}`
        );
        setUsers(response.data.users);
        setTotalUsers(response.data.total);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, [currentPage, usersPerPage]);

  const handleViewClick = (user) => {
    setSelectedUser(user);
  };

  const closeModal = () => {
    setSelectedUser(null);
  };
  const totalPages = Math.ceil(totalUsers / usersPerPage);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className="ml-72 p-6">
      <h1 className="text-3xl font-semibold mb-4 text-gray-800">User List</h1>
      <div className="overflow-x-auto bg-white shadow-md rounded-lg">
        <table className="table-auto border-collapse w-full text-sm">
          <thead className="bg-gray-200">
            <tr>
              <th className="border p-2">Name</th>
              <th className="border p-2">Email</th>
              <th className="border p-2">Image</th>
              <th className="border p-2">Phone</th>
              <th className="border p-2">Verification Status</th>
              <th className="border p-2">Certificate</th> {/* Added certificate column */}
              <th className="border p-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.email} className="hover:bg-gray-100">
                <td className="border p-2">{user.name}</td>
                <td className="border p-2">{user.email}</td>
                <td className="border p-2">
                  {user.image && (
                    <img
                      src={`${BASE_URL}${user.image}`}
                      alt={user.name}
                      className="w-16 h-16 object-cover cursor-pointer"
                    />
                  )}
                </td>
                <td className="border p-2">{user.phone}</td>
                <td className="border p-2">
                  {user.isVerified ? "Verified" : "Not Verified"}
                </td>
                <td className="border p-2">
                  {user.certificateUrl && (
                    <a
                      href={`${BASE_URL}/${user.certificateUrl}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:text-blue-700"
                    >
                      View Certificate
                    </a>
                  )}
                </td>
                <td className="border p-2">
                  <button
                    onClick={() => handleViewClick(user)}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex justify-center space-x-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-blue-500 text-white px-4 py-2 rounded disabled:bg-gray-300"
        >
          Previous
        </button>
        <span className="self-center text-lg">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="bg-blue-500 text-white px-4 py-2 rounded disabled:bg-gray-300"
        >
          Next
        </button>
      </div>
      {selectedUser && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-60 flex items-center justify-center z-50">
          <div className="relative bg-white p-6 rounded-lg max-w-2xl w-full shadow-2xl transform transition-all duration-300 ease-in-out">
            <h2 className="text-3xl font-semibold text-gray-800 mb-6">User Details</h2>
            <div className="grid grid-cols-2 gap-4 text-gray-700">
              <p><strong>Name:</strong> {selectedUser.name}</p>
              <p><strong>Father's Name:</strong> {selectedUser.fatherName || "N/A"}</p>
              <p><strong>Mother's Name:</strong> {selectedUser.motherName || "N/A"}</p>
              <p><strong>Address:</strong> {selectedUser.address || "N/A"}</p>
              <p><strong>State:</strong> {selectedUser.state || "N/A"}</p>
              <p><strong>City:</strong> {selectedUser.city || "N/A"}</p>
              <p><strong>Pincode:</strong> {selectedUser.pincode || "N/A"}</p>
              <p><strong>Education Qualification:</strong> {selectedUser.educationQualification || "N/A"}</p>
              <p><strong>Email:</strong> {selectedUser.email}</p>
              <p><strong>Phone:</strong> {selectedUser.phone}</p>
              <p><strong>Gender:</strong> {selectedUser.gender}</p>
              {selectedUser.image && (
                <div className="col-span-2">
                  <strong>Image:</strong>
                  <img
                    src={`${BASE_URL}${selectedUser.image}`}
                    alt={selectedUser.name}
                    className="w-32 h-32 object-cover mt-2"
                  />
                </div>
              )}
             
            </div>
            <button
              onClick={closeModal}
              className="absolute top-0 right-0 text-white bg-red-600 p-3 rounded-full transform hover:scale-110 transition-transform duration-300"
            >
              X
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserTable;
