import React from "react";
import { FaTachometerAlt, FaSignOutAlt } from "react-icons/fa";
import { BiSolidCategory } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { LOGOUT } from "../constant/constant";

const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const tokenItemStr = sessionStorage.getItem("token");
      if (!tokenItemStr) {
        alert("No token found. Please log in again.");
        return;
      }
      const tokenItem = JSON.parse(tokenItemStr);
      const now = new Date();
      if (now.getTime() > tokenItem.expiry) {
        sessionStorage.removeItem("token"); 
        alert("Session has expired. Please log in again.");
        navigate("/login");
        return;
      }
      await axios.post(
        LOGOUT,
        {},
        {
          headers: {
            Authorization: `Bearer ${tokenItem.value}`,
          },
        }
      );

      sessionStorage.clear();
      alert("Logged out successfully");
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error.response?.data || error.message);
      alert("Failed to log out. Please try again.");
    }
  };
  

  return (
    <div className="bg-[#1A2738] h-screen w-72 fixed shadow-lg flex flex-col justify-between">
      <div className="overflow-y-auto">
        <div className="flex items-center mt-6 px-6 py-4">
          <div className="ml-4">
            <div className="text-2xl font-bold text-white leading-tight">
              Beginner Course
            </div>
          </div>
        </div>

        <nav className="mt-8 ">
          <div className="text-xs font-semibold text-[#9DC33A] pl-8 mb-3 tracking-wider">
            OVERVIEW
          </div>

          <Link
            to="/add-chapter"
            className="flex items-center text-white py-3 pl-8 pr-4 mt-2 hover:bg-[#2A3D56] rounded-md transition-all duration-300 shadow-md"
          >
            <BiSolidCategory className="text-lg" />
            <span className="ml-4 text-base font-medium">Add Chapter</span>
          </Link>
          <Link
            to="/add-question"
            className="flex items-center text-white py-3 pl-8 pr-4 hover:bg-[#2A3D56] rounded-md transition-all duration-300 shadow-md"
          >
            <FaTachometerAlt className="text-lg" />
            <span className="ml-4 text-base font-medium">Add Question</span>
          </Link>

          <Link
            to="/question"
            className="flex items-center text-white py-3 pl-8 pr-4 mt-2 hover:bg-[#2A3D56] rounded-md transition-all duration-300 shadow-md"
          >
            <BiSolidCategory className="text-lg" />
            <span className="ml-4 text-base font-medium">Question List</span>
          </Link>
          <Link
            to="/user-list"
            className="flex items-center text-white py-3 pl-8 pr-4 mt-2 hover:bg-[#2A3D56] rounded-md transition-all duration-300 shadow-md"
          >
            <BiSolidCategory className="text-lg" />
            <span className="ml-4 text-base font-medium">User</span>
          </Link>
        </nav>
      </div>
      <div className="px-6 py-4">
        <button
          onClick={handleLogout}
          className="flex items-center w-full text-white py-3 pl-8 pr-4 hover:bg-[#2A3D56] rounded-md transition-all duration-300 shadow-md"
        >
          <FaSignOutAlt className="text-lg" />
          <span className="ml-4 text-base font-medium">Logout</span>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
