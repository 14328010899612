import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import Sidebar from "./components/Sidebar";
import ProductList from "./components/AddQuestion";
import AddChapter from "./components/AddChapter";
import QuestionList from "./components/QuestionList";
import LoginPage from "./components/Login";
import Signup from "./components/Signup";
import UserTable from "./components/UserTable";

const isAuthenticated = () => {
  const token = sessionStorage.getItem("token");
  return !!token;
};
const ProtectedRoute = ({ children }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/" replace />;
  }
  return children;
};

const App = () => {
  const location = useLocation();
  const hideSidebarRoutes = ["/"];

  return (
    <>
      {!hideSidebarRoutes.includes(location.pathname) && <Sidebar />}
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/add-question"
          element={
            <ProtectedRoute>
              <ProductList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-chapter"
          element={
            <ProtectedRoute>
              <AddChapter />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-list"
          element={
            <ProtectedRoute>
              <UserTable/>
            </ProtectedRoute>
          }
        />
        <Route
          path="/question"
          element={
            <ProtectedRoute>
              <QuestionList />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
