import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { GET_ADD_CHAPTER, GET_CHAPTERNAME } from "../constant/constant";

const ChapterList = () => {
  const [chapters, setChapters] = useState([]);
  const [selectedChapterQuestions, setSelectedChapterQuestions] = useState([]);
  const [loading, setLoading] = useState(false);

  
  useEffect(() => {
    const fetchChapters = async () => {
      try {
        const response = await axios.get(GET_ADD_CHAPTER);
        setChapters(response.data.data);  
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to fetch chapters",
        });
      }
    };

    fetchChapters();
  }, []);

 
  const fetchQuestionsForChapter = async (chapterName) => {
    setLoading(true);  

    try {
      const response = await axios.get(
             `${GET_CHAPTERNAME}/admin/questions?chapterName=${chapterName}`
       
      );
      setSelectedChapterQuestions(response.data.data); 
      Swal.fire({
        icon: "success",
        title: "Questions fetched successfully",
        text: `Questions for chapter "${chapterName}" loaded`,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Failed to fetch questions for chapter: ${chapterName}`,
      });
    } finally {
      setLoading(false); 
    }
  };

  return (
    <div className="ml-72 p-4">
      <h1 className="text-2xl font-semibold mb-6">Chapters</h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {chapters.length === 0 ? (
          <div>Loading chapters...</div>
        ) : (
          chapters.map((chapter) => (
            <div
              key={chapter._id}
              className="bg-white shadow-md rounded-lg p-4 cursor-pointer hover:shadow-xl transition-all"
              onClick={() => fetchQuestionsForChapter(chapter.chapter)}
            >
              <h2 className="text-xl font-semibold text-center">{chapter.chapter}</h2>
            </div>
          ))
        )}
      </div>

      {loading && (
        <div className="text-center mt-4">
          <div className="loader">Loading...</div>
        </div>
      )}
      {selectedChapterQuestions.length > 0 && (
        <div className="mt-6">
          <h2 className="text-2xl font-semibold">Questions for Selected Chapter</h2>
          <ul className="mt-4">
            {selectedChapterQuestions.map((question, index) => (
              <li key={index} className="bg-gray-100 p-4 rounded-lg mb-4">
                <h3 className="font-medium">{question.question}</h3>
                <ul className="mt-2">
                  {question.options.map((option, idx) => (
                    <li key={idx} className="ml-4">{option}</li>
                  ))}
                </ul>
                <p className="mt-2 text-sm text-gray-500">Correct Answer: {question.correctAnswer}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ChapterList;
